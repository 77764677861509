import React from "react"
import PropTypes from "prop-types"

import Nav from "./nav"
import Seo from "./seo"

const Layout = ({ children }) => {
  const year = new Date().getFullYear()

  React.useEffect(() => {
    try {
      const UIkit = require("uikit/dist/js/uikit.min")
      const icons = require("uikit/dist/js/uikit-icons.min")
      UIkit.use(icons)
    } catch (e) {
      console.error(e)
    }
  }, [])

  return (
    <>
      <Seo />
      <Nav />
      <main>{children}</main>
      <p style={{ marginTop: 30, textAlign: "center" }}>
        Copyright &copy; Laslas.life {year}
      </p>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
