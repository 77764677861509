import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

const Nav = () => (
  <div>
    <div>
      <nav className="uk-navbar-container" data-uk-navbar>
        <div className="uk-navbar-left">
          <ul className="uk-navbar-nav">
            <li>
              <Link to="/">Laslas.life</Link>
            </li>
          </ul>
        </div>

        <div className="uk-navbar-right">
          <ul className="uk-navbar-nav">
            <StaticQuery
              query={graphql`
                query {
                  allStrapiCategory {
                    edges {
                      node {
                        strapiId
                        name
                      }
                    }
                  }
                }
              `}
              render={data =>
                data.allStrapiCategory.edges.map(category => {
                  return (
                    <li key={category.node.strapiId}>
                      <Link to={`/category/${category.node.strapiId}`}>
                        {category.node.name}
                      </Link>
                    </li>
                  )
                })
              }
            />
            <li key="about__page">
              <Link to="/about">About</Link>
            </li>
            <li>
              <div style={{marginTop: 15}}>
              <a
                className="uk-button uk-button-secondary uk-button-large"
                href={`${process.env.GATSBY_API_URL}/admin`}
              >
                Login
              </a>
              </div>
            
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
)

export default Nav
